solumaxLoggerApp
    .directive('logIndex', function(
        LogModel) {

        return {
            templateUrl: '/solumax/logger/plugins/v1.0/log/directives/index/logIndex.html',
            scope: {
                loggableType: '@',
                loggableId: '@',
            },
            link: function(scope, elem, attrs) {

                var unloaded = true

                var watches = ['loggableType', 'loggableId']
                watches.forEach(function(watch) {

                    scope.$watch('scope.' + watch, function(newVal) {
                        if (newVal != 'undefined') {
                            retrieve()
                        }
                    })
                })

                function retrieve() {

                    if (scope.loggableType && scope.loggableId && unloaded) {

                        unloaded = false

                        LogModel.index({ loggable_type: scope.loggableType, loggable_id: scope.loggableId })
                            .then(function(res) {
                                scope.logs = res.data.data
                                scope.meta = res.data.meta
                            })
                    }

                }

            }
        }
    })
